// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home {
}

.samplesBackground {
	width: 100%;
	position: absolute;
	background: #fff;
	/*
	#91d1c2
	#8491b4
	#b09c85
	#3c5488
	#4dbbd5
	*/
}

.samplesBox {
	margin: auto;
	background: #fff;
	width: 800px;
	padding: 30px;
	border-radius: 5px;
	margin-top: 50px;
	/*border: 1px solid #ccc;*/
	box-shadow: 0px 0px 50px #ddd;
}

.samplesBox h1 {
	font-size: 16pt;
}

.hidden {
	display: none;
}

.studyBox {
	margin-bottom: 10px;
	cursor: pointer;
}

.studyBox .studies {
	margin-left: 20px;
}

.sampleHomeBox {
  padding: 5px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  margin-left: -8px;
}

a.buttonLink {
  font-weight: bold;
  color: #fff;
  display: inline-block;
  padding: 3px;
  border-radius: 3px;
  background: #3c5488;
  text-decoration: none;
}

a.buttonLink:hover {
  color: #fff;
  background: #2d4068;
}`, "",{"version":3,"sources":["webpack://./src/Home.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;CACC,WAAW;CACX,kBAAkB;CAClB,gBAAgB;CAChB;;;;;;EAMC;AACF;;AAEA;CACC,YAAY;CACZ,gBAAgB;CAChB,YAAY;CACZ,aAAa;CACb,kBAAkB;CAClB,gBAAgB;CAChB,0BAA0B;CAC1B,6BAA6B;AAC9B;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,mBAAmB;CACnB,eAAe;AAChB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".Home {\n}\n\n.samplesBackground {\n\twidth: 100%;\n\tposition: absolute;\n\tbackground: #fff;\n\t/*\n\t#91d1c2\n\t#8491b4\n\t#b09c85\n\t#3c5488\n\t#4dbbd5\n\t*/\n}\n\n.samplesBox {\n\tmargin: auto;\n\tbackground: #fff;\n\twidth: 800px;\n\tpadding: 30px;\n\tborder-radius: 5px;\n\tmargin-top: 50px;\n\t/*border: 1px solid #ccc;*/\n\tbox-shadow: 0px 0px 50px #ddd;\n}\n\n.samplesBox h1 {\n\tfont-size: 16pt;\n}\n\n.hidden {\n\tdisplay: none;\n}\n\n.studyBox {\n\tmargin-bottom: 10px;\n\tcursor: pointer;\n}\n\n.studyBox .studies {\n\tmargin-left: 20px;\n}\n\n.sampleHomeBox {\n  padding: 5px;\n  border-bottom: 1px solid #ccc;\n  margin-bottom: 5px;\n  margin-left: -8px;\n}\n\na.buttonLink {\n  font-weight: bold;\n  color: #fff;\n  display: inline-block;\n  padding: 3px;\n  border-radius: 3px;\n  background: #3c5488;\n  text-decoration: none;\n}\n\na.buttonLink:hover {\n  color: #fff;\n  background: #2d4068;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
