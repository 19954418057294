// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Authentication {
}

.authenticationBox {
	width: 500px;
	padding: 30px;
	margin: auto;
	margin-top: 25px;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0px 0px 50px #ddd;
}

.authenticationBackground {
	/*background: #91d1c2;*/
	/*background: #3c5488;*/
	width: 100%;
	height: 100%;
	position: absolute;
}

.loginLogo {
	width: 180px;
	margin: auto;
	margin-left: 120px;
	margin-top: 10px;
	margin-bottom: 10px;
}

input.large {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    padding: 10px;
    /* border: 2px solid #ddd;*/
    border: none;
    background: #eee;
    border-radius: 5px;
}

button.large {
	background: #00a087;
	color: #fff;
	border: 1px solid #008973;
	margin-top: 10px !important;
	margin-left: 0px !important;
	padding: 7px;
}

.errorMessage {
	background: #e64b35;
	color: #fff;
	padding: 10px;
	border-radius: 3px;
	font-weight: bold;
	margin-top: 10px;
}

.errorMessage a {
	color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/Authentication.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,YAAY;CACZ,gBAAgB;CAChB,gBAAgB;CAChB,kBAAkB;CAClB,6BAA6B;AAC9B;;AAEA;CACC,uBAAuB;CACvB,uBAAuB;CACvB,WAAW;CACX,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,YAAY;CACZ,YAAY;CACZ,kBAAkB;CAClB,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,2BAA2B;IAC3B,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;CACC,mBAAmB;CACnB,WAAW;CACX,yBAAyB;CACzB,2BAA2B;CAC3B,2BAA2B;CAC3B,YAAY;AACb;;AAEA;CACC,mBAAmB;CACnB,WAAW;CACX,aAAa;CACb,kBAAkB;CAClB,iBAAiB;CACjB,gBAAgB;AACjB;;AAEA;CACC,WAAW;AACZ","sourcesContent":[".Authentication {\n}\n\n.authenticationBox {\n\twidth: 500px;\n\tpadding: 30px;\n\tmargin: auto;\n\tmargin-top: 25px;\n\tbackground: #fff;\n\tborder-radius: 5px;\n\tbox-shadow: 0px 0px 50px #ddd;\n}\n\n.authenticationBackground {\n\t/*background: #91d1c2;*/\n\t/*background: #3c5488;*/\n\twidth: 100%;\n\theight: 100%;\n\tposition: absolute;\n}\n\n.loginLogo {\n\twidth: 180px;\n\tmargin: auto;\n\tmargin-left: 120px;\n\tmargin-top: 10px;\n\tmargin-bottom: 10px;\n}\n\ninput.large {\n    margin-top: 10px;\n    width: 100%;\n    height: 40px;\n    padding: 10px;\n    /* border: 2px solid #ddd;*/\n    border: none;\n    background: #eee;\n    border-radius: 5px;\n}\n\nbutton.large {\n\tbackground: #00a087;\n\tcolor: #fff;\n\tborder: 1px solid #008973;\n\tmargin-top: 10px !important;\n\tmargin-left: 0px !important;\n\tpadding: 7px;\n}\n\n.errorMessage {\n\tbackground: #e64b35;\n\tcolor: #fff;\n\tpadding: 10px;\n\tborder-radius: 3px;\n\tfont-weight: bold;\n\tmargin-top: 10px;\n}\n\n.errorMessage a {\n\tcolor: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
