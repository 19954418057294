// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OrderSelector {
	margin: 20px;
}

.OrderSelector .singleSelect {
	border: 2px solid #ccc;
	border-radius: 5px;
	padding: 10px;
	margin-right: 10px; 
	margin-bottom: 10px;
	width: 300px;
	display: inline-block;;
}

.newSelect {
	opacity: 0.66;
}

.inlineBlock {
	display: inline-block;
}

span.thenBy {
	display: inline-block;
	margin-right: 10px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 10px;
}

.OrderSelector select {
	width: calc(100% - 65px);
    height: 30px;
    font-weight: bold;
    outline: none;
    display: inline-block;
}

.OrderSelector .sortMode {
	display: inline-block;
	text-align: right;
	margin-left: 10px;
	padding-left: 5px;
	padding-right: 5px;
	background: red;
	color: #fff;
	border-radius: 3px;
	background: #4dbbd5;
}

.sortMode i {
	font-size: 20px;
	cursor: pointer;
}

.ascending {
	background: #e64b35 !important;
}

.transparent {
	opacity: 0.4;
}

.deleteSorting {
	margin-left: 5px;
}

.deleteSorting i {
	font-size: 19px;
}`, "",{"version":3,"sources":["webpack://./src/OrderSelector.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;CACC,sBAAsB;CACtB,kBAAkB;CAClB,aAAa;CACb,kBAAkB;CAClB,mBAAmB;CACnB,YAAY;CACZ,qBAAqB;AACtB;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,qBAAqB;CACrB,kBAAkB;CAClB,yBAAyB;CACzB,iBAAiB;CACjB,eAAe;AAChB;;AAEA;CACC,wBAAwB;IACrB,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,qBAAqB;AACzB;;AAEA;CACC,qBAAqB;CACrB,iBAAiB;CACjB,iBAAiB;CACjB,iBAAiB;CACjB,kBAAkB;CAClB,eAAe;CACf,WAAW;CACX,kBAAkB;CAClB,mBAAmB;AACpB;;AAEA;CACC,eAAe;CACf,eAAe;AAChB;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,eAAe;AAChB","sourcesContent":[".OrderSelector {\n\tmargin: 20px;\n}\n\n.OrderSelector .singleSelect {\n\tborder: 2px solid #ccc;\n\tborder-radius: 5px;\n\tpadding: 10px;\n\tmargin-right: 10px; \n\tmargin-bottom: 10px;\n\twidth: 300px;\n\tdisplay: inline-block;;\n}\n\n.newSelect {\n\topacity: 0.66;\n}\n\n.inlineBlock {\n\tdisplay: inline-block;\n}\n\nspan.thenBy {\n\tdisplay: inline-block;\n\tmargin-right: 10px;\n\ttext-transform: uppercase;\n\tfont-weight: bold;\n\tfont-size: 10px;\n}\n\n.OrderSelector select {\n\twidth: calc(100% - 65px);\n    height: 30px;\n    font-weight: bold;\n    outline: none;\n    display: inline-block;\n}\n\n.OrderSelector .sortMode {\n\tdisplay: inline-block;\n\ttext-align: right;\n\tmargin-left: 10px;\n\tpadding-left: 5px;\n\tpadding-right: 5px;\n\tbackground: red;\n\tcolor: #fff;\n\tborder-radius: 3px;\n\tbackground: #4dbbd5;\n}\n\n.sortMode i {\n\tfont-size: 20px;\n\tcursor: pointer;\n}\n\n.ascending {\n\tbackground: #e64b35 !important;\n}\n\n.transparent {\n\topacity: 0.4;\n}\n\n.deleteSorting {\n\tmargin-left: 5px;\n}\n\n.deleteSorting i {\n\tfont-size: 19px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
