// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatusBar {
}

.statusBar {
	padding: 8px;
	/*background: #7e6148;*/
	background: #47645c;
	color: #fff;
	font-weight: bold;
	/*add gradient*/
	/*
	#e64b35
	#4dbbd5
	#b09c85
	#7e6148
	*/
}

.statusBar a {
	color: #fff;
	padding-left: 5px;
}

.statusBar a:hover {
	color: #fff;
	text-decoration: overline;
}
`, "",{"version":3,"sources":["webpack://./src/StatusBar.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;CACC,YAAY;CACZ,uBAAuB;CACvB,mBAAmB;CACnB,WAAW;CACX,iBAAiB;CACjB,eAAe;CACf;;;;;EAKC;AACF;;AAEA;CACC,WAAW;CACX,iBAAiB;AAClB;;AAEA;CACC,WAAW;CACX,yBAAyB;AAC1B","sourcesContent":[".StatusBar {\n}\n\n.statusBar {\n\tpadding: 8px;\n\t/*background: #7e6148;*/\n\tbackground: #47645c;\n\tcolor: #fff;\n\tfont-weight: bold;\n\t/*add gradient*/\n\t/*\n\t#e64b35\n\t#4dbbd5\n\t#b09c85\n\t#7e6148\n\t*/\n}\n\n.statusBar a {\n\tcolor: #fff;\n\tpadding-left: 5px;\n}\n\n.statusBar a:hover {\n\tcolor: #fff;\n\ttext-decoration: overline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
