// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Note {

}

.noteBox {
	margin: auto;
	background: #fff;
	width: 800px;
	padding: 30px;
	border-radius: 5px;
	margin-top: 50px;
	/*border: 1px solid #ccc;*/
	box-shadow: 0px 0px 50px #ddd;
}

.noteBox h1 {
	font-size: 16pt;
}

.singleNote {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 20px;
}

.noteHeader {
  background: #fff;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  border-radius: 2px 2px 0px 0px;
}

.inlineLeft {
  display: inline-block;
  width: 50%;
}

.inlineRight {
  display: inline-block;
  width: 50%;
  text-align: right;
}

.noteBody {
  padding: 10px;
  background: #f5f5f5;
  border-radius: 0px 0px 2px 2px;
}

.noteText {
  margin-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Note.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;CACC,YAAY;CACZ,gBAAgB;CAChB,YAAY;CACZ,aAAa;CACb,kBAAkB;CAClB,gBAAgB;CAChB,0BAA0B;CAC1B,6BAA6B;AAC9B;;AAEA;CACC,eAAe;AAChB;;AAEA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,6BAA6B;EAC7B,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".Note {\n\n}\n\n.noteBox {\n\tmargin: auto;\n\tbackground: #fff;\n\twidth: 800px;\n\tpadding: 30px;\n\tborder-radius: 5px;\n\tmargin-top: 50px;\n\t/*border: 1px solid #ccc;*/\n\tbox-shadow: 0px 0px 50px #ddd;\n}\n\n.noteBox h1 {\n\tfont-size: 16pt;\n}\n\n.singleNote {\n  border: 1px solid #ccc;\n  border-radius: 3px;\n  margin-top: 20px;\n}\n\n.noteHeader {\n  background: #fff;\n  border-bottom: 1px solid #ccc;\n  padding: 10px;\n  border-radius: 2px 2px 0px 0px;\n}\n\n.inlineLeft {\n  display: inline-block;\n  width: 50%;\n}\n\n.inlineRight {\n  display: inline-block;\n  width: 50%;\n  text-align: right;\n}\n\n.noteBody {\n  padding: 10px;\n  background: #f5f5f5;\n  border-radius: 0px 0px 2px 2px;\n}\n\n.noteText {\n  margin-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
