// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tiering {
}

.finding {
  background: #eee;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
}

.findingHeader {
}

.findingLeft {
  display: inline-block;
  width: 30%;
}

.findingRight {
  display: inline-block;
  width: 70%;
  text-align: right;
}

.findingGene {
  font-size: 20px;
  font-weight: bold;
}

.acmgResult {
  background: #dc0000;
  display: inline-block;
  padding: 5px;
  border-radius: 3px;
  margin-left: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
}

.likely_pathogenic {
  background: orange;
}

.clinvarResult {
  background: darkred;
  display: inline-block;
  padding: 5px;
  border-radius: 3px;
  margin-left: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
}

table.secondaryFindings {
  width: 100%;
}

table.secondaryFindings > tr {
  border-bottom: 1px solid #ccc;
}

td.padded {
  padding: 3px !important;
}

tr.highlighted {
  background: yellow;
}

td.bold {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/SecondaryFindings.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;AACA;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".Tiering {\n}\n\n.finding {\n  background: #eee;\n  padding: 15px;\n  border-radius: 5px;\n  margin-top: 10px;\n}\n\n.findingHeader {\n}\n\n.findingLeft {\n  display: inline-block;\n  width: 30%;\n}\n\n.findingRight {\n  display: inline-block;\n  width: 70%;\n  text-align: right;\n}\n\n.findingGene {\n  font-size: 20px;\n  font-weight: bold;\n}\n\n.acmgResult {\n  background: #dc0000;\n  display: inline-block;\n  padding: 5px;\n  border-radius: 3px;\n  margin-left: 5px;\n  color: #fff;\n  font-weight: bold;\n  font-size: 11px;\n}\n\n.likely_pathogenic {\n  background: orange;\n}\n\n.clinvarResult {\n  background: darkred;\n  display: inline-block;\n  padding: 5px;\n  border-radius: 3px;\n  margin-left: 5px;\n  color: #fff;\n  font-weight: bold;\n  font-size: 11px;\n}\n\ntable.secondaryFindings {\n  width: 100%;\n}\n\ntable.secondaryFindings > tr {\n  border-bottom: 1px solid #ccc;\n}\n\ntd.padded {\n  padding: 3px !important;\n}\n\ntr.highlighted {\n  background: yellow;\n}\n\ntd.bold {\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
