// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GenotypeSelector {
	margin: 20px;
}

.GenotypeSelector select.genotype {
	width: 200px;
    height: 30px;
    font-weight: bold;
    outline: none;
    display: inline-block;
}

span.patientId {
	font-weight: bold;
	width: 150px;
	display: inline-block;
}

div.genotype {
	border: 2px solid #ccc;
	padding: 10px;
	margin-top: 10px;
	border-radius: 5px;
	width: 374px;
}`, "",{"version":3,"sources":["webpack://./src/GenotypeSelector.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;CACC,YAAY;IACT,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,qBAAqB;AACzB;;AAEA;CACC,iBAAiB;CACjB,YAAY;CACZ,qBAAqB;AACtB;;AAEA;CACC,sBAAsB;CACtB,aAAa;CACb,gBAAgB;CAChB,kBAAkB;CAClB,YAAY;AACb","sourcesContent":[".GenotypeSelector {\n\tmargin: 20px;\n}\n\n.GenotypeSelector select.genotype {\n\twidth: 200px;\n    height: 30px;\n    font-weight: bold;\n    outline: none;\n    display: inline-block;\n}\n\nspan.patientId {\n\tfont-weight: bold;\n\twidth: 150px;\n\tdisplay: inline-block;\n}\n\ndiv.genotype {\n\tborder: 2px solid #ccc;\n\tpadding: 10px;\n\tmargin-top: 10px;\n\tborder-radius: 5px;\n\twidth: 374px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
