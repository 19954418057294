// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Admin {
	margin: 20px;
}

button.small {
	border: 1px solid;
	margin: 0px !important;
}

.Admin table {
	margin-top: 10px;
	width: 100%;
}

.Admin input {
	margin-left: 10px;
}

.Admin label {
	margin-right: 10px;
}

.Admin td {
	border: 1px solid #000;
}

.sampleBox {
	height: 100px;
	overflow-y: scroll;
}

.igvPath {
  width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/Admin.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;CACC,iBAAiB;CACjB,sBAAsB;AACvB;;AAEA;CACC,gBAAgB;CAChB,WAAW;AACZ;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,sBAAsB;AACvB;;AAEA;CACC,aAAa;CACb,kBAAkB;AACnB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".Admin {\n\tmargin: 20px;\n}\n\nbutton.small {\n\tborder: 1px solid;\n\tmargin: 0px !important;\n}\n\n.Admin table {\n\tmargin-top: 10px;\n\twidth: 100%;\n}\n\n.Admin input {\n\tmargin-left: 10px;\n}\n\n.Admin label {\n\tmargin-right: 10px;\n}\n\n.Admin td {\n\tborder: 1px solid #000;\n}\n\n.sampleBox {\n\theight: 100px;\n\toverflow-y: scroll;\n}\n\n.igvPath {\n  width: 90%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
